import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

export const getCountries = createAsyncThunk(
  "masters/getCountries",
  async () => {
    try {
      const response = await API.get(`/countries?limit=all&populate=flag`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCourseTypes = createAsyncThunk(
  "masters/getCourseTypes",
  async () => {
    try {
      const response = await API.get(`/course-types?limit=all`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const readFile = createAsyncThunk(
  "masters/readFile",
  async (getFileId: String) => {
    try {
      const response = await API.get(`/files/read/${getFileId}`, {
        responseType: "arraybuffer",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const shortlistCollegeCourse = createAsyncThunk(
  "masters/shortlistCollegeCourse",
  async (shortlistData: any) => {
    try {
      const response = await API.post(`/shortlists`, shortlistData);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateShortlistCollegeCourse = createAsyncThunk(
  "masters/updateShortlistCollegeCourse",
  async (shortlistData: any) => {
    try {
      const response = await API.put(
        `/shortlists/${shortlistData._id}`,
        shortlistData
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getShortlistedCollegeCourse = createAsyncThunk(
  "masters/getShortlistedCollegeCourse",
  async (getProps: any) => {
    const URIDecoded = encodeURIComponent(JSON.stringify(getProps));
    try {
      const response = await API.get(
        `/shortlists?limit=all&filters=${URIDecoded}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCoursesFromAlgolia = createAsyncThunk(
  "masters/getCoursesFromAlgolia",
  async (searchData: any) => {
    try {
      const response = await API.post(`/courses/algolia/search`, searchData);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
