import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./styles/variables.css";
import "./styles/common.css";
const StudentLayout = lazy(() => import("./components/Layouts/StudentLayout"));
const HomePage = lazy(() => import("./pages/HomePage"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Waitlist = lazy(() => import("./pages/Waitlist"));

const routesConfig = [
  {
    element: <StudentLayout />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<div className="container">Loading...</div>}>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: "waitlist",
        element: (
          <Suspense fallback={<div className="container">Loading...</div>}>
            <Waitlist />
          </Suspense>
        ),
      },
      {
        path: "terms-and-conditions",
        element: (
          <Suspense fallback={<div className="container">Loading...</div>}>
            <TermsAndConditions />
          </Suspense>
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <Suspense fallback={<div className="container">Loading...</div>}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
    ],
  },
];

const router = createBrowserRouter(routesConfig);

function App() {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
