import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getCollegeByCountries,
  getCollegesWithFilters,
  getCollegesWithFiltersActive,
} from "../services/collegeApi";

interface VendorState {
  collegeByCountryLoading: "idle" | "loading" | "succeeded" | "failed";
  collegeWithFiltersLoading: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  collegeByCountries: any[];
  collegeWithFilters: any[];
  collegeWithFiltersTotal: number;
  searchRequest: {
    degree: string | null;
    courses: any[];
    countries: any[];
    tutionFee: {
      min: number;
      max: number;
    };
    populate: string | null;
    limit: number;
    skip: number;
    isFilterActive: Boolean;
  };
  hasMore: boolean;
}

const initialState = {
  collegeByCountryLoading: "idle",
  collegeWithFiltersLoading: "idle",
  error: null,
  collegeByCountries: [],
  collegeWithFilters: [],
  collegeWithFiltersTotal: 0,
  searchRequest: {
    degree: null,
    courses: [],
    countries: [],
    tutionFee: {
      min: 0,
      max: 5000000,
    },
    populate: "loanProviders,logo",
    limit: 3,
    skip: 0,
    isFilterActive: false,
  },
  hasMore: true,
} as VendorState;

const collegeSlice = createSlice({
  name: "collegeData",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.searchRequest = action.payload;
    },
    resetState: (state) => {
      state.collegeWithFiltersLoading = "idle";
      state.collegeWithFiltersTotal = 0;
      state.collegeWithFilters = [];
      state.searchRequest = {
        degree: null,
        courses: [],
        countries: [],
        tutionFee: {
          min: 0,
          max: 5000000,
        },
        populate: "loanProviders,logo",
        limit: 3,
        skip: 0,
        isFilterActive: false,
      };
      state.hasMore = true;
    },
    resetStateLoading: (state) => {
      state.collegeWithFiltersLoading = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCollegeByCountries.pending, (state, action) => {
        state.collegeByCountryLoading = "loading";
        state.error = null;
      })
      .addCase(
        getCollegeByCountries.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.collegeByCountryLoading = "succeeded";
          state.error = null;
          state.collegeByCountries = action.payload;
        }
      )
      .addCase(
        getCollegeByCountries.rejected,
        (state, action: PayloadAction<any>) => {
          state.collegeByCountryLoading = "failed";
          state.error = action.payload;
        }
      )

      .addCase(getCollegesWithFilters.pending, (state, action) => {
        state.collegeWithFiltersLoading = "loading";
        state.error = null;
      })
      .addCase(
        getCollegesWithFilters.fulfilled,
        (state, action: PayloadAction<any>) => {
          // if (action.payload.data.length === 0) {
          //   state.collegeWithFilters = [];
          //   state.hasMore = false;
          //   state.collegeWithFiltersTotal = 0;
          // } else {
          let newSkip = (state.searchRequest.skip += state.searchRequest.limit);
          state.searchRequest = { ...state.searchRequest, skip: newSkip };
          state.error = null;
          state.collegeWithFilters = [
            ...state.collegeWithFilters,
            ...action.payload.data,
          ];
          state.collegeWithFiltersTotal = action.payload.total;
          state.hasMore = state.searchRequest.skip <= action.payload.total;
          // }
          console.log(state.searchRequest);
          state.collegeWithFiltersLoading = "succeeded";
        }
      )
      .addCase(
        getCollegesWithFilters.rejected,
        (state, action: PayloadAction<any>) => {
          state.collegeWithFiltersLoading = "failed";
          state.error = action.payload;
        }
      );
  },
});

export const { resetState, setFilters, resetStateLoading } =
  collegeSlice.actions;
export default collegeSlice.reducer;
