import axios from "axios";

const development =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

let baseURL = process.env.REACT_APP_FUTURX_BASEURL;
if (!development) baseURL = process.env.REACT_APP_FUTURX_BASEURL;

const API = axios.create({ baseURL: baseURL });

export default API;
