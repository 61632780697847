import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getCountries,
  getCourseTypes,
  shortlistCollegeCourse,
  getShortlistedCollegeCourse,
  updateShortlistCollegeCourse,
} from "../services/masterApi";

interface VendorState {
  countriesLoading: "idle" | "loading" | "succeeded" | "failed";
  courseTypesLoading: "idle" | "loading" | "succeeded" | "failed";
  shortlistLoading: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  countries: any[];
  courseTypes: any[];
  shortlists: any[];
}

const initialState = {
  countriesLoading: "idle",
  courseTypesLoading: "idle",
  shortlistLoading: "idle",
  error: null,
  countries: [],
  courseTypes: [],
  shortlists: [],
} as VendorState;

const masterSlice = createSlice({
  name: "masterData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCountries.pending, (state, action) => {
        state.countriesLoading = "loading";
        state.error = null;
      })
      .addCase(getCountries.fulfilled, (state, action: PayloadAction<[]>) => {
        state.countriesLoading = "succeeded";
        state.error = null;
        state.countries = action.payload;
      })
      .addCase(getCountries.rejected, (state, action: PayloadAction<any>) => {
        state.countriesLoading = "failed";
        state.error = action.payload;
      })

      .addCase(getCourseTypes.pending, (state, action) => {
        state.courseTypesLoading = "loading";
        state.error = null;
      })
      .addCase(getCourseTypes.fulfilled, (state, action: PayloadAction<[]>) => {
        state.courseTypesLoading = "succeeded";
        state.error = null;
        state.courseTypes = action.payload;
      })
      .addCase(getCourseTypes.rejected, (state, action: PayloadAction<any>) => {
        state.courseTypesLoading = "failed";
        state.error = action.payload;
      })

      .addCase(getShortlistedCollegeCourse.pending, (state, action) => {
        state.shortlistLoading = "loading";
        state.error = null;
      })
      .addCase(
        getShortlistedCollegeCourse.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.shortlistLoading = "succeeded";
          state.error = null;
          state.shortlists = action.payload;
        }
      )
      .addCase(
        getShortlistedCollegeCourse.rejected,
        (state, action: PayloadAction<any>) => {
          state.shortlistLoading = "failed";
          state.error = action.payload;
        }
      )

      .addCase(shortlistCollegeCourse.pending, (state, action) => {
        state.shortlistLoading = "loading";
        state.error = null;
      })
      .addCase(
        shortlistCollegeCourse.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.shortlistLoading = "succeeded";
          state.error = null;
          state.shortlists?.push(action.payload);
        }
      )
      .addCase(
        shortlistCollegeCourse.rejected,
        (state, action: PayloadAction<any>) => {
          state.shortlistLoading = "failed";
          state.error = action.payload;
        }
      )

      .addCase(updateShortlistCollegeCourse.pending, (state, action) => {
        state.shortlistLoading = "loading";
        state.error = null;
      })
      .addCase(
        updateShortlistCollegeCourse.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.shortlistLoading = "succeeded";
          state.error = null;

          state.shortlists = state.shortlists?.map((shor) =>
            shor._id === action.payload._id ? action.payload : shor
          );
        }
      )
      .addCase(
        updateShortlistCollegeCourse.rejected,
        (state, action: PayloadAction<any>) => {
          state.shortlistLoading = "failed";
          state.error = action.payload;
        }
      );
  },
});

export default masterSlice.reducer;
