import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

export const getCollegeByCountries = createAsyncThunk(
  "college/getCollegeByCountries",
  async (getCountries: any) => {
    try {
      const response = await API.get(`/colleges/promoted`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// export const getCollegesWithFilters = createAsyncThunk(
//   "college/getCollegesWithFilters",
//   async ({ limit, skip }: { limit: number; skip: number }) => {
//     try {
//       const response = await API.get(
//         `/colleges?populate=loanProviders,logo&limit=${limit}&skip=${skip}`
//       );
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const getCollegesWithFilters = createAsyncThunk(
  "college/getCollegesWithFilters",
  async (getData: any) => {
    let selectedCourseArray =
      getData.courses && getData.courses.map((a: any) => a.value);
    const sendRequest = {
      degree: getData.degree?.value,
      courses: selectedCourseArray && selectedCourseArray.map(String),
      countries:
        getData.countries && getData.countries.map((a: any) => a.value),
      tutionFee: getData.tutionFee
        ? {
            min: getData.tutionFee[0],
            max: getData.tutionFee[1],
          }
        : {},
      placement: getData.placement,
      populate: "loanProviders,logo",
      limit: getData.limit,
      skip: getData.skip,
    };
    try {
      const response = await API.post(`/colleges/search`, sendRequest);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCollegesWithFiltersActive = createAsyncThunk(
  "college/getCollegesWithFilters",
  async (getData: any) => {
    try {
      const response = await API.post(`/colleges/search`, getData);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCollegeById = createAsyncThunk(
  "college/getCollegeById",
  async (getProps: any) => {
    console.log(getProps);
    try {
      const response = await API.get(
        `/colleges/algolia/${getProps}?populate=admissionCriteria,testScore.test,rankings.rank`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCoursesByCollegeId = createAsyncThunk(
  "college/getCoursesByCollegeId",
  async (getCollegeId: String) => {
    const URIDecoded = encodeURIComponent(
      JSON.stringify({ college: getCollegeId })
    );
    try {
      const response = await API.get(
        `courses-by-college?limit=100&filter=${URIDecoded}&populate=course`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCollegesFromAlgolia = createAsyncThunk(
  "college/getCollegesFromAlgolia",
  async (searchData: any) => {
    try {
      const response = await API.post(`/colleges/algolia/search`, searchData);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
